import React from "react"
import Layout from "../components/layout"

import "../fontawesome/css/all.min.css"
import "./index.css"


const OurMission = () => (
  <div className="App">
    <Layout>

          <div className="container">
    <div className="row">
      <div className="col-md-12">
  <h1>Our Mission</h1>

  <h2>
    <strong>Mission Statement</strong>
  </h2>
  <p>
    We desire to provide wholesome, interesting activities for our youth. This
    provides them with opportunities to demonstrate responsibility and
    leadership in community service, both here and abroad.
  </p>
  <h2>Car Show Bible Verse</h2>
  <p>
    “I can do all things through Christ Who strengthens me.”&nbsp;
    -Philippians 4:13
  </p>
  <p />
  <p>
In 2004, after the first show, the Young Adults Founders of this event chose to give to local, national, and global missions. We have continued to honor that decision to this day.
  </p>
  
  <p>
  The Pardeeville Community Car & Truck Show (PCCTS, Inc.) is a registered 501.c3 non-profit.
  </p>
  
  </div>
  </div>
      </div>
    </Layout>
  </div>
)
export default OurMission
